import { Injectable} from '@angular/core';
import { TreeNode } from 'primeng/api';
import { HttpClient } from  "@angular/common/http";
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NodeService{

  private _url_treeJSON : string = 'https://api.digi-lion.com:5050/jsonapi/jsontreenoderepresentation';

  constructor(private http: HttpClient) {
  }

 getTreeData(jsonString: string): any {
  return this.http.post<JSON>(this._url_treeJSON, jsonString).pipe(map(res => {
    return <TreeNode>res;
  }), catchError(err => {
    if(err.status == 500) {
      return err.error;
    } else {
      return throwError(new Error(err))
    }
  }));
}

}
