import { Component} from '@angular/core';
import { AppSettingsService} from '../app/app-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent{
  logoString = "{ ... }";
  title = 'JSON Validator & Viewer';

  constructor() {
  }

}
