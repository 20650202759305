import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import {TreeNode} from 'primeng/api';
import { NodeService } from 'src/app/node.service';
import { AppSettingsService } from 'src/app/app-settings.service';
import { from } from 'rxjs';
import {MessageService} from 'primeng/api';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-tree-representation',
  templateUrl: './tree-representation.component.html',
  styleUrls: ['./tree-representation.component.less']
})
export class TreeRepresentationComponent implements OnChanges, OnInit {

  @Input()
  jsonData: JSON;

  @Input()
  reload: boolean;

  _displayRepresentation: boolean;

  visibleSidebar4;

  limitMaxCharacter: Number = 600000;

  jsonTreeList : TreeNode[];
  jsonTree : TreeNode;

  public isMobile: boolean = false;
  public isTablet: boolean = false;
  public isDesktopDevice: boolean = false;

  public isErrorResponseServeur : Boolean;
  public isMaxLimitedJSON : Boolean;
  public isLoading : Boolean;
  public errorResponseServeur: string = "Your JSON data don't permit to build a tree";
  public requestIsLimited: string = "{ \"This online and free Tree representation is limited for maximum ".concat(String(this.limitMaxCharacter)).concat(" characters\" }");
  public loadingString : string = "loading ... (depending your json size) ...";

  constructor(private nodeService: NodeService, private messageService: MessageService, private appSettingsService : AppSettingsService, private deviceService: DeviceDetectorService) {
    this.detectDevice();
   }

  ngOnInit() {
    this._displayRepresentation = false;
    this.isLoading = false;
    this.isErrorResponseServeur = false;
    this.isMaxLimitedJSON = false;
    if(this.jsonData != null){
      this.getTreeDataFromService(JSON.stringify(this.jsonData));
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    const jsonData: SimpleChange = changes.jsonData;
    if(this._displayRepresentation){
    if(jsonData.currentValue != null && this.jsonData != null){
      this.jsonData = jsonData.currentValue;
      if(this.reload) {
        this.isErrorResponseServeur = false;
        this.isMaxLimitedJSON = false;
        if(JSON.stringify(this.jsonData).length < this.limitMaxCharacter){
          this.getTreeDataFromService(JSON.stringify(this.jsonData));
        } else {
          this.isMaxLimitedJSON = true;
        }
      }
    }
    }
}

  getTreeDataFromService(jsonString : string){
    this.isLoading = true;
    this.nodeService.getTreeData(jsonString).subscribe((files) => {
      if (files.data != null) {
        this.jsonTreeList = files.data;
        this.isLoading = false;
      } else {
        this.isErrorResponseServeur = true;
        this.isLoading = false;
      }
    });
  }

  clear() {
    this.messageService.clear();
  }

  @Input()
  set displayRepresentation(displayRepresentation: boolean) {
    this._displayRepresentation = displayRepresentation;
}

detectDevice() {
  this.isMobile = this.deviceService.isMobile();
  this.isTablet = this.deviceService.isTablet();
  this.isDesktopDevice = this.deviceService.isDesktop();
}


}
