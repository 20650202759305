import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TreeModule } from 'primeng/tree';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/primeng';
import { PanelModule } from 'primeng/primeng';
import { ButtonModule } from 'primeng/primeng';
import { RadioButtonModule } from 'primeng/primeng';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SidebarModule } from 'primeng/sidebar';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TreeRepresentationComponent } from './tree-representation/tree-representation.component';

import { NodeService } from 'src/app/node.service';
import { WriteSpaceComponent } from './write-space/write-space.component';

import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AceEditorModule } from 'ng2-ace-editor';

import { CopyClipboardModule } from './copy-module/copy-clipboard.module';

import { DeviceDetectorModule } from 'ngx-device-detector';

import "brace/mode/json";

@NgModule({
  declarations: [
    AppComponent,
    TreeRepresentationComponent,
    WriteSpaceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TreeModule,
    ToastModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AccordionModule,
    PanelModule,
    ButtonModule,
    RadioButtonModule,
    InputTextareaModule,
    NgxJsonViewerModule,
    AceEditorModule,
    CopyClipboardModule,
    DropdownModule,
    InputSwitchModule,
    SidebarModule,
    DeviceDetectorModule.forRoot()
    ],
  providers: [
    NodeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
