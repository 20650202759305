import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import {MessageService} from 'primeng/api';
import {TreeRepresentationComponent} from '../tree-representation/tree-representation.component';

@Component({
  selector: 'app-write-space',
  templateUrl: './write-space.component.html',
  styleUrls: ['./write-space.component.less'],
  providers: [MessageService, TreeRepresentationComponent]
})
export class WriteSpaceComponent implements OnInit {

  inputJson: string;
  targetJson: JSON;
  isError : Boolean;
  errorMessage: string;
  isMinified: Boolean;
  minifiedJSON: string;
  jsonEscape: Object;
  isJsonEscape: Boolean;
  isBeautify : Boolean;
  isEmpty: Boolean;
  indentation : number;
  indentations: SelectItem[];
  collapse: boolean;
  displayRepresentation: boolean;
  reload : boolean;
  isNeedValidation: boolean;
  forgetJSONMessage: string = "You forgot to enter your JSON data !";
  clipboardMessage: string = "JSON successfully copied to clipboard";
  jsonErrorMessage: string = "Your JSON is not correct";

  constructor(private messageService: MessageService, private treeRepresentation: TreeRepresentationComponent) {
    this.indentations = [
      {label:'0', value:0},
      {label:'2', value:2},
      {label:'4', value:4},
      {label:'6', value:6},
      {label:'8', value:8}
  ];
   }

  ngOnInit() {
    // this.reload = false;
    this.indentation = 4;
    this.collapse = true;
    this.displayRepresentation = false;
    this.isNeedValidation = true;
    this.isEmpty = false;
  }

  beautifyJson(inputJson: string){
      if(inputJson) {
      try {
        this.initBoolean();
        this.targetJson = JSON.parse(inputJson);
      } catch (error) {
        this.isError = true;
        this.errorMessage = error;
        this.addErrorMessage();
      }
      this.isBeautify = true;
    } else {
      this.initBoolean()
      this.addEmptyMessage();
    }

  }

  validateJson(inputzone : string){
    if(inputzone) {
      this.isEmpty = false;
      try {
        this.initBoolean();
        this.targetJson = JSON.parse(inputzone);
      } catch (error) {
        this.isError = true;
        this.errorMessage = error;
        this.addErrorMessage();
      }
      if (this.isError == false) {
        this.isMinified = true;
        this.minifiedJSON = "JSON OK !";
      }   
   } else {
      this.initBoolean()
      this.addEmptyMessage();
    }

  }

  minifyJson(inputzone : string){
    if (inputzone) {
      try {
        this.initBoolean();
        var jsonChecked = JSON.parse(inputzone);
      } catch (error) {
        this.isError = true;
        this.errorMessage = error;
        this.addErrorMessage();
      }
    if(this.isError == false) {
      this.isMinified = true;
      this.minifiedJSON = JSON.stringify(jsonChecked).replace("&nbsp;",'');
    }
  } else {
    this.initBoolean()
    this.addEmptyMessage();
  }

  }

  treeRepresentationValidation(inputJson: string) {
  if(this.displayRepresentation){
    this.displayRepresentation = false;
  } else {
    this.validateJson(inputJson);
    this.needValidation(inputJson);
    if(!this.isError && !this.isEmpty) {
      this.collapse = false;
      this.displayRepresentation = true;
    }
  }
  }

  public jsonEscaper(val: string) {
    if (val) {
      try {
      this.initBoolean();
      var jsonParsed : JSON = JSON.parse(val);
      } catch (error) {
        this.isError = true;
        this.errorMessage = error;
        this.addErrorMessage();
      }
      if(this.isError == false) {
        this.isJsonEscape = true;
        this.jsonEscape = JSON.stringify(jsonParsed);
      }
    } else {
      this.initBoolean()
      this.addEmptyMessage();
    }
  }

  public needValidation(newInputString : string) {
      if(this.inputJson == newInputString){
        this.isNeedValidation = false;
      };
      this.isNeedValidation = true;
  }

  checkStringEmpty(val: string): boolean {
      if (val.length == 0 || val == '' || val == null){
        return true
      }
    return false;
  }

  set code (v) {
    this.inputJson = v;    
  }

  public stamp(val: any): string {
    if(val != null && val != "" ) {
      if (typeof val === "object") {
        return JSON.stringify(val, null, this.indentation);
      } else if (typeof val === "string") {
        return val;
      }
    }
  }

  public textTocopy(): any{
    var elementToCopy: Object;
    if(this.isMinified && !this.isError){
      elementToCopy = this.minifiedJSON;
    } else if(this.isJsonEscape && !this.isError) {
      elementToCopy =  JSON.stringify(this.jsonEscape);
    } else if (this.isBeautify && !this.isError) {
      elementToCopy = this.targetJson;
    } else {
      elementToCopy = '';
    }
    return elementToCopy;
  }

  addClipboardMessage() {
    this.messageService.add({severity:'info', summary:'Information', detail:this.clipboardMessage});
  }

  addEmptyMessage() {
    this.isEmpty = true;
    this.messageService.add({severity:'warn', summary:'About your JSON', detail:this.forgetJSONMessage});
  }

  addErrorMessage() {
    this.messageService.add({severity:'error', summary:'SyntaxError', detail:this.jsonErrorMessage});
  }


  clear() {
    this.messageService.clear();
  }

  initBoolean(){
    this.isMinified = false;
    this.isError = false;
    this.isJsonEscape = false;
    this.minifiedJSON = null;
    this.targetJson = null;
    this.jsonEscape = null;
    this.isBeautify = false;
  }

}
